<template>
  <page-layout>
    <span slot="header-name">{{ $tf('persons') }}</span>

    <template slot="header-content-actions">
      <i class="el-icon-loading" v-if="state.playing && $store.state.server.connected"></i>
      <el-button name="play-btn" size="small" type="primary" v-if="!state.playing" @click="togglePlaying" :disabled="!!state.filter.current.page"
        ><i class="fa fa-play"></i
      ></el-button>
      <el-button name="pause-btn" size="small" :plain="true" v-else @click="togglePlaying"><i class="fa fa-pause"></i></el-button>
    </template>

    <table-navigation slot="header-content-navigation" :state="state"></table-navigation>

    <div slot="content" v-loading="state.loading" class="cols flex-wrap persons">
      <person-item :key="'item-' + item.id" :item="item" v-for="item in state.items"></person-item>
    </div>

    <table-navigation slot="footer-content-navigation" :state="state"></table-navigation>
  </page-layout>
</template>

<script>
import PersonItem from './item';
import TableNavigation from '@/components/tables/navigation.vue';
import PageLayout from '@/components/page/layout';

export default {
  components: {
    PageLayout,
    PersonItem,
    TableNavigation
  },
  name: 'page-persons',
  computed: {
    model() {
      return 'persons';
    },
    state() {
      return this.$store.state.persons;
    },
    stats() {
      return this.$store.state.stats;
    }
  },
  mounted() {
    this.$cache.enable(true);
  },
  beforeDestroy() {
    this.$cache.enable(false);
  },
  methods: {
    togglePlaying() {
      this.state.playing = !this.state.playing;
    },
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    showImage(url, box) {
      this.$store.dispatch('showImage', { src: url, box: box });
    },
    getCamera(item) {
      return this.$store.state.cameras.items.find((v) => v.id === item.camera) || { name: this.$tf('camera | not_found,,1') };
    },
    getCameraGroup(item) {
      let camera = this.getCamera(item);
      return this.$store.state.camera_groups.items.find((v) => v.id === camera.group) || { name: '' };
    }
  }
};
</script>

<style lang="stylus">
.event-reaction-content {
  margin-top: 1rem;
}

.filter--page-limit {
  display: inline-block;
  width: 5rem;
}

.tag-loading {
  cursor: none !important;
  opacity: .2;
}

.persons {
  justify-content: center;
}

.download-btn {
  margin-top: 4rem;
}
</style>
