<template>
  <div class="person-container" @click.prevent="personClickHandler(item)">
    <div class="rows rows--center">
      <div class="person-image-container">
        <div v-if="bestEvent">
          <img
            :key="'person-' + i"
            :src="bestEvent.thumbnail"
            class="person-image-thumbnail"
            :class="{ 'person-image-thumbnail--highlight': animation.faceHighlight }"
            v-for="i in eventsFakeArray"
            :style="getStyle(i)"
          />
        </div>
      </div>

      <div class="person-text text-tetriary text-s text-center" :class="{ 'person-text--highlight': animation.faceHighlight }">
        {{ $tf('events') }} {{ item.events_count }},
        <span @click.stop
          ><router-link :to="getContactsUrl(item)"><i class="fa fa-handshake-o"></i></router-link
        ></span>
        <br v-if="item.matched_dossier" />
        <span v-if="item.matched_dossier" @click.stop
          >{{ $tf('dossier') }} <router-link :to="getDossierUrl(item)">{{ dossierName }}</router-link></span
        >
        <br v-if="item.matched_lists" />
        <dossier-lists-round-inline :ids="item.matched_lists" v-if="item.matched_lists" />
      </div>
    </div>
  </div>
</template>
<script>
import Color from 'color';
import qs from 'qs';
import _ from '@/apps/common/lodash';
import DossierListsRoundInline from '../dossier-lists/inline.round.items';

const Item = {
  Height: 168,
  AcknowledgedHeight: 120
};

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  components: { DossierListsRoundInline },
  props: {
    item: Object
  },
  data: function () {
    return {
      dossier: null,
      animation: {
        height: Item.AcknowledgedHeight,
        faceHighlight: false
      }
    };
  },
  computed: {
    dossierName() {
      return this.dossier ? this.$filters.shortString(this.dossier.name) : this.item.matched_dossier;
    },
    bestEvent() {
      return this.item.best_event;
    },
    eventsFakeArray() {
      const count = Math.min(this.item.events_count || 1, 3);
      return new Array(count).fill(0).map((v, k) => k);
    },
    state() {
      return this.$store.state.persons;
    }
  },
  watch: {
    'item.events_count': function () {
      this.animation.faceHighlight = true;
      setTimeout(() => {
        this.animation.faceHighlight = false;
      }, 300);
    }
  },
  created() {},
  mounted() {
    this.tryLoadDossier();
  },
  methods: {
    tryLoadDossier() {
      this.dossier = null;
      if (this.item.matched_dossier) this.$store.dispatch('get_dossiers', { id: this.item.matched_dossier }).then((v) => (this.dossier = v));
    },
    getFontColor(item) {
      let color = Color('#' + item.color),
        isLight = color.light();

      return isLight ? '#000' : 'rgba(255, 255, 255, 0.8)';
    },
    getStyle(i) {
      const padding = 10,
        imageSize = 120 - padding * i,
        opacity = Math.max(1 - i * 0.3, 0.1),
        count = Math.min(this.item.events_count, 3);

      return {
        opacity,
        right: i * padding * 0.5 + 'px',
        height: imageSize + 'px',
        width: imageSize + 'px',
        top: i * padding * 1.5 + 'px',
        'z-index': count - i
      };
    },
    getPersonEventsUrl(v) {
      let filter = Object.assign({}, this.$store.state.person_events.filter.empty, { person_in: v.id }),
        cleanFilter = _.pickBy(filter, (v) => !!v);

      return '#/person-events/filter/' + qs.stringify(cleanFilter, { sort: alphabeticalSort });
    },
    getDossierUrl(v) {
      return '/dossiers/' + v.matched_dossier;
    },
    getContactsUrl(v) {
      return v.matched_dossier
        ? `/contacts/filter/limit=10&matched_dossier=${v.matched_dossier}&ordering=-id`
        : `/contacts/filter/id=${v.id}&limit=10&ordering=-id`;
    },
    personClickHandler(v) {
      this.$router.push({ path: this.getPersonEventsUrl(v).slice(1) });
    },
    showImage(url, box) {
      this.$store.dispatch('showImage', { src: url, box: box });
    }
  }
};
</script>
